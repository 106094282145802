'use strict';

angular.module('windmanagerApp')
  .directive('viewTurbine', function(NgMap, $timeout) {
    return {
      templateUrl: 'components/directives/turbine/viewTurbine/viewTurbine.html',
      restrict: 'EA',
      scope: {
        turbine: '='
      },
      controller: 'ViewTurbineCtrl',
      controllerAs: 'vtc',
      link: function(scope, element, attrs) {
        NgMap.getMap().then(function(map) {
          $timeout(()=> {
            google.maps.event.trigger(map, 'resize');
          });
        })
      }
    };
  });
